import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IframeResizer from 'iframe-resizer-react'

const Iframe = styled(IframeResizer)`
  width: 1px;
  min-width: 100%;

  border: none;
  outline: none;
`

const PardotForm = ({ link }) => {
  return <Iframe
    src={link}
  />
}

PardotForm.propTypes = {
  link: PropTypes.string.isRequired,
}

export default PardotForm
